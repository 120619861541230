<script setup lang="ts">
import type { PageStoryblok } from "~/types/storyblok";

const props = defineProps<{ blok: PageStoryblok }>();
useStoryblokSeo(props.blok);
</script>

<template>
	<div v-editable="blok">
		<StoryblokComponent v-for="(body, index) in blok.body" :key="body._uid" :blok="body" :first="!index" />
	</div>
</template>
